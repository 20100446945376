.login-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border: 1.0px solid #9b9b9b;
  border-radius: 6px;
  height: 100vh;
  max-height: 500px;
  width: 100vw;
  max-width: 400px;
  margin-top: 80px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fcfcfc;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}